import React from 'react';

import Layout from "../components/layout.js";
import Back from "../components/back.js";
import Hero from "../components/hero.js";

// import AppleAppStoreBageImg from "../images/apple-app-store-badge-outline.svg";

const IndexPage = () => {

  return (
    <Layout>
    	<Back/>
    	<h1><Hero>Terms of Use</Hero></h1>

    	<h2>General terms</h2>
		<p>These General Terms and Conditions (hereinafter "GTC"), the information and associated rules of use refer to the ambia.app website and the Ambia apps (hereinafter collectively referred to as "Ambia"). They are managed by the legal entity listed in the legal notice (hereinafter "Operator"). The GTC govern the business relationship between the users of Ambia and the Operator.</p>
		<p>By using Ambia, you unconditionally accept and agree to the following terms and conditions.</p>
		<p>The GTC, the information and the rules of use may change at any time. The changes are binding.</p>
		<p>To the extent permitted by law, Ambia completely excludes any liability (including slight negligence and coincidence, ii), liability for consequential damages (e.g. loss of profit) and liability for auxiliary persons) and in connection with the use of the app. The same applies in the event of unlawful interference with data transmission or processing or the mobile device. Finally, Ambia accepts no liability for the correct and complete use of Ambia with devices on which the manufacturer's protective measures have been reduced or disabled.</p>
		<p>Ambia reserves the right to modify, fundamentally redesign or remove individual information, rules or functions without prior notice. Therefore, Ambia cannot be held responsible for the consequences of such changes.</p>
		<p>Ambia does not guarantee that the website and the apps and the functionalities offered can be used without disruption at all times and that the information requested can be transmitted smoothly and without errors.</p>
		<p>Ambia cannot guarantee that the information provided here is up-to-date, accurate and complete at all times.</p>
		<p>The Ambia app can be accessed by downloading the Ambia app from the Apple App Store or the Google Play Store.</p>
		<p>The use of the app in combination with clicking on the checkbox in the registration process constitutes consent to the use of the transmitted data for further offers and information (newsletter and advertising) and to their transmission (by e-mail), storage and processing within Switzerland, the Principality of Liechtenstein, Germany and the USA. Your data will not be passed on to third parties unless the data is forwarded to the sponsor for the purpose of sending the prize in the event of a win. If you no longer wish to receive offers and information from Ambia, you can revoke the use of your data at any time at info@ambia.app.</p>
		<p>Ambia undertakes to exercise the necessary care in handling your personal data at all times and to comply with Swiss data protection regulations.</p>
		<p>Error tools (including AppCenter from Microsoft in the United States) are used to obtain error reports after the app crashes.</p>
		<p>To redeem coupons or vouchers digitally, they must be activated via the Ambia app. After activation, the digital coupons and vouchers are immediately available for redemption and become invalid after the time displayed in the app has expired.</p>
		<p>Limited prizes, coupons and vouchers will only be issued while stocks last.</p>
		
    </Layout>
    )
}

export default IndexPage
